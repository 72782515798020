import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IntlProviderWrapper } from './core/intl-provider-wrapper';
import { getQueryParams, guid } from 'src/utils';
import { ub, getConfigInfo, getCommodity } from 'src/api';
import Detail from './views/detail';
import Order from './views/order';
import OrderConfirm from './views/order-confirm';
import SearchOrder from './views/search-order';
import EventBusManager from './core/event-bus-manager';
import { BusinessEvent } from './common/constant';
import UserActionDataModel from './model/user-action-data-model';
import UserActionExtraModel from './model/user-action-extra-model';
import UserActionEventModel from './model/user-action-event-model';
import Logger from 'src/utils/logger';

const logger = new Logger('app');

// const Router: any = process.env.REACT_APP_DEPLOY_ENV!.lastIndexOf('production') >= 0 ? BrowserRouter : HashRouter;
const Router = BrowserRouter;

interface Prop {}
interface State {
    init: boolean;
    config: any;
}

export default class App extends Component<Prop, State> {
    constructor(props: Prop) {
        super(props);
        this.state = {
            init: false,
            config: {},
        };
    }
    private appendHeader(header: string) {
        if (!header?.trim()) {
            return;
        }
        $('head').append(header);
    }
    private appendFooter(footer: string) {
        if (!footer?.trim()) {
            return;
        }
        $('body').append(footer);
    }
    async componentWillMount() {
        const params: any = getQueryParams(window.location.href);
        // let { promoteId } = params;
        let promoteId: any = (window as any).pid;
        // 兼容测试环境
        if (promoteId === '<?= pid ?>') {
            promoteId = params.p;
        }

        let config = JSON.parse(sessionStorage.getItem('config') as string);
        let commodity = JSON.parse(sessionStorage.getItem('commodity') as string);
        if (!promoteId) {
            promoteId = params.promoteId ?? sessionStorage.getItem('promoteId');
            if (!promoteId) {
                throw new Error('can not get promote id.');
            }
        }
        sessionStorage.setItem('promoteId', promoteId);
        if (!config) {
            const { data: configData } = await getConfigInfo(promoteId);
            config = configData;
            sessionStorage.setItem('config', JSON.stringify(config));
        }
        if (!commodity) {
            const { data: commodityData } = await getCommodity(promoteId);
            commodity = commodityData;
            sessionStorage.setItem('commodity', JSON.stringify(commodity));
        }
        $('head title').text(config.title);
        this.setState({ config: config, init: true }, () => {
            const { header, footer } = this.state.config;
            this.appendHeader(header);
            this.appendFooter(footer);
            this.setIcon(config);
            this.triggerViewContentFbq();
        });
    }

    setIcon(config: any) {
        if (config && config.icon) {
            var link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = config.icon;
            document.getElementsByTagName('head')[0].appendChild(link);
        }
    }

    userActionHandler = (data: UserActionDataModel, extra: UserActionExtraModel) => {
        let token = sessionStorage.getItem('token');
        const userActionEvent: UserActionEventModel = {
            event: extra.type,
            data: data,
            token,
        };
        ub(userActionEvent);
    };

    triggerViewContentFbq = () => {
        const { config } = this.state;
        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq('track', 'ViewContent');
                } catch (error) {}
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('ViewContent');
                } catch (error) {}
            }
        }
        if (config.hasLineAds) {
            if (window._lt) {
                try {
                    if (config.adsConfig.line?.tagId) {
                        window._lt('send', 'cv', { type: 'ViewItem' }, [config.adsConfig.line?.tagId]);
                    }
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelViewContent) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelViewContent,
                        });
                    }
                } catch (error) {}
            }
        }
    };

    componentDidMount() {
        EventBusManager.add(BusinessEvent.USER_ACTION, this.userActionHandler);
    }
    render() {
        if (!this.state.init) {
            return null;
        }
        return (
            <IntlProviderWrapper>
                <Router>
                    <Switch>
                        <Route exact path="/cod/detail" component={Detail} />
                        <Route exact path="/cod/order" component={Order} />
                        <Route exact path="/cod/order-confirm/:orderId" component={OrderConfirm} />
                        <Route exact path="/cod/search-order" component={SearchOrder} />
                        <Route component={Detail} />
                    </Switch>
                </Router>
            </IntlProviderWrapper>
        );
    }
}
