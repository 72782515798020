import React from 'react';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import { searchOrder } from 'src/api';
import Search from './components/search';
import SearchResult from './components/search-result';

import './index.scss';

const SearchStatus = {
    SEARCH: 'search',
    RESULT: 'result',
};
interface Prop {}
interface State {
    status: string;
    orders: any[];
}

@injectIntl()
export default class SearchOrder extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            status: SearchStatus.SEARCH,
            orders: [],
        };
    }
    searchHandler = async (keyword: string) => {
        const commodity = JSON.parse(sessionStorage.getItem('commodity') as string);
        const params = {
            commodityId: commodity.id,
            orderCodeOrPhone: keyword.replace('#', ''),
        };
        const { data } = await searchOrder(params);
        this.setState({ status: SearchStatus.RESULT, orders: data });
    };
    // resultBackHandler = () => {
    //     this.setState({
    //         status: SearchStatus.SEARCH,
    //     });
    // };
    searchBackHandler = () => {
        this.props.history?.goBack();
    };
    componentDidMount() {}

    render() {
        return (
            <>
                {this.state.status === SearchStatus.SEARCH && (
                    <Search onSearch={this.searchHandler} onBack={this.searchBackHandler} />
                )}
                {this.state.status === SearchStatus.RESULT && (
                    <SearchResult orders={this.state.orders} onBack={this.searchBackHandler} />
                )}
            </>
        );
    }
}
