import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';

import './index.scss';

interface Prop {
    orders: any[];
    onBack: () => void;
}
interface State {}

@injectIntl()
export default class SearchResult extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }
    backHomeHandler = () => {
        this.props.onBack();
    };
    componentDidMount() {}

    render() {
        const { orders } = this.props;
        return (
            <div className="result">
                <h1>
                    <img alt="成功" src={require('src/assets/images/success.png')} />
                    <FormattedMessage id="order.query.success" />
                </h1>
                <div className="innner order_div success">
                    <div className="order" style={{ minHeight: 'calc(100vh - 244px)' }}>
                        {orders?.map(order => {
                            return (
                                <ul key={order?.code}>
                                    <li>
                                        <label>
                                            <FormattedMessage id="order-number" />
                                        </label>
                                        <span id="order_no"> : {order?.code}</span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="order-success.combo" />
                                        </label>
                                        <span> : {order?.comboName}</span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="commodity" />
                                        </label>
                                        <span> : {order?.commodityName}</span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="order.detail" /> :
                                        </label>
                                        <br />
                                        {order?.spuArr?.map((spu: any) => {
                                            return (
                                                <Fragment key={spu.spuName}>
                                                    <span>{spu.spuName}</span>
                                                    <br />
                                                    {spu?.skuArr?.map((sku: any, index: number) => {
                                                        return (
                                                            <Fragment key={index}>
                                                                <span>
                                                                    <FormattedMessage id="order.count" /> : {sku.qty}
                                                                </span>
                                                                <p>
                                                                    {sku?.attributeValueArr?.map((attr: any) => {
                                                                        if (attr.attributeValueLabel.indexOf('http') === 0) {
                                                                            return (
                                                                                <span key={attr.attributeValueLabel}>
                                                                                    {attr.attributeName + ':' +
                                                                                    (
                                                                                        <img
                                                                                            src={
                                                                                                attr.attributeValueLabel +
                                                                                                '!thn90-90'
                                                                                            }
                                                                                            alt=""
                                                                                        />
                                                                                    )}
                                                                                </span>
                                                                            );
                                                                        }
                                                                        return (
                                                                            <span key={attr.attributeValueLabel}>
                                                                                {attr.attributeName +
                                                                                    ':' +
                                                                                    attr.attributeValueLabel}
                                                                            </span>
                                                                        );
                                                                    })}
                                                                </p>
                                                            </Fragment>
                                                        );
                                                    })}
                                                </Fragment>
                                            );
                                        })}
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="price" />
                                        </label>
                                        <span>
                                            : <b>{order?.totalPrice}</b>
                                        </span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="pay.method" />
                                        </label>
                                        <span>
                                            : <FormattedMessage id="cod" />{' '}
                                        </span>
                                    </li>
                                    {/* <li>
                                        <label>
                                            <FormattedMessage id="delivery.method" />
                                        </label>
                                        <span>{order.distributionMode}</span>
                                    </li> */}
                                    <li>
                                        <label>
                                            <FormattedMessage id="ship-info.name" />
                                        </label>
                                        <span>
                                            : <b>{order.shipNickname}</b>
                                        </span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="ship-info.phone" />
                                        </label>
                                        <span>
                                            : <b>{order.shipPhone}</b>
                                        </span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="email" />
                                        </label>
                                        <span> : {order?.shipEmail} </span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="ship-info.region" />
                                        </label>
                                        <span>
                                            : <b>{order.shipState}</b>
                                        </span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="ship-info.detail-address" />
                                        </label>
                                        <span> : {order?.shipFullAddress} </span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="zip-code" />
                                        </label>
                                        <span> : {order?.shipZipCode} </span>
                                    </li>
                                    <li>
                                        <label>
                                            <FormattedMessage id="ship-info.message" />
                                        </label>
                                        <span> : {order?.message} </span>
                                    </li>
                                    {/* {order?.logisticsName && (
                                        <li>
                                            <label>
                                                <FormattedMessage id="index.order.query.logistics.name" />
                                            </label>
                                            <span>{order?.logisticsName} </span>
                                        </li>
                                    )}
                                    {order?.logisticsNumber && (
                                        <li>
                                            <label>
                                                <FormattedMessage id="index.order.query.logistics.num" />
                                            </label>
                                            <span>{order?.logisticsNumber} </span>
                                        </li>
                                    )} */}
                                    <li>
                                        <span></span>
                                    </li>
                                </ul>
                            );
                        })}
                    </div>

                    <div style={{ color: 'red', textAlign: 'center', width: '100%', padding: '10px 0px' }}>
                        <FormattedMessage id="security.info" />
                    </div>
                    <div className="foot">
                        <a className="foot_btn" onClick={this.backHomeHandler}>
                            <FormattedMessage id="go-back" />
                        </a>
                        <p></p>
                    </div>
                </div>
            </div>
        );
    }
}
