import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Logger from 'src/utils/logger';
import Base from 'src/core/base';
import { getRegionList } from 'src/api/region';
import { remoteRegion } from 'src/api';

const logger = new Logger('ShipInfo');
interface Prop {
    selectedPackage: any;
    onShipInfoChange: (info: any) => void;
    onRemoteRegion: (region: any) => void;
}
interface State {
    config: any;
    commodity: any;
    selectedDistribution: any;
    realName: string;
    realName0: string;
    realName1: string;
    phone: string;
    shipEmail: string;
    shipMethod: string;
    detailAddress: string;
    shipZipCode: string;
    shipZipCode0: string;
    shipZipCode1: string;
    selectedRegion: any[];
    remark: string;
    region: any[];
    remoteLogisticsFee: number;
}
@injectIntl()
export default class ShipInfo extends Base<Prop, State> {
    private triggeredAddToCart: boolean = false;

    constructor(props: any) {
        super(props);
        this.state = {
            config: {},
            commodity: {},
            selectedDistribution: {},
            realName: '',
            realName0: '',
            realName1: '',
            phone: '',
            shipEmail: '',
            shipMethod: '',
            detailAddress: '',
            shipZipCode: '',
            shipZipCode0: '',
            shipZipCode1: '',
            selectedRegion: [],
            remark: '',
            region: [],
            remoteLogisticsFee: 0,
        };
    }

    private async getRegionList(distributionMode: any = {}, parentLevel: number = 0, parentId: string | null = null) {
        const config = JSON.parse(sessionStorage.getItem('config') as string);
        const promoteId = sessionStorage.getItem('promoteId');
        const regionParams = {
            parentId,
            promoteId: promoteId,
            parentLevel: parentLevel,
            regionCode: config?.regionCode,
            distributionMode: distributionMode.value,
        };
        this.setState({ config, selectedDistribution: distributionMode });
        let response = await getRegionList(regionParams);
        return response;
    }

    changeHandler = ({
        realName0,
        realName1,
        phone,
        shipMethod,
        detailAddress,
        shipEmail,
        shipZipCode0,
        shipZipCode1,
        remark,
    }: any) => {
        this.setState(
            {
                realName0: realName0 ?? this.state.realName0,
                realName1: realName1 ?? this.state.realName1,
                realName: (realName0 ?? this.state.realName0) + (realName1 ?? this.state.realName1),
                phone: phone ?? this.state.phone,
                shipEmail: shipEmail ?? this.state.shipEmail,
                shipMethod: shipMethod ?? this.state.shipMethod,
                detailAddress: detailAddress ?? this.state.detailAddress,
                shipZipCode0: shipZipCode0 ?? this.state.shipZipCode0,
                shipZipCode1: shipZipCode1 ?? this.state.shipZipCode1,
                shipZipCode:
                    this.state.commodity.regionCode === 'JPN'
                        ? (shipZipCode0 ?? this.state.shipZipCode0) + '-' + (shipZipCode1 ?? this.state.shipZipCode1)
                        : shipZipCode0 ?? this.state.shipZipCode0,
                remark: remark ?? this.state.remark,
            },
            () => {
                this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
            },
        );
    };

    changeDistributionHandler = async (dis: any) => {
        this.setState({ region: [] });
        this.setState({ remoteLogisticsFee: 0 });
        this.props?.onRemoteRegion({ remoteLogisticsFee: 0, regionId: '' });
        const { data } = await this.getRegionList(dis);
        this.setState({ region: data, selectedDistribution: dis, selectedRegion: [] }, () => {
            if (data?.[0]?.level >= 0) {
                this.regionChangeHandler(data?.[0]?.value, data?.[0]?.level);
            }
            this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
        });
    };

    regionChangeHandler = async (value: string, level: number = -1) => {
        if (level === 1) {
            let findNode = false;
            for (let i = 0, iLen = this.state.region.length; i < iLen; i++) {
                const region = this.state.region[i];
                if (region.value === value) {
                    const { data } = await this.getRegionList(this.state.selectedDistribution, region.level, region.id);
                    region.children = data;
                    this.setState({ selectedRegion: [region] }, () => {
                        this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
                    });
                    findNode = true;
                    if (data?.length > 0) {
                        this.regionChangeHandler(data[0].value, level + 1);
                    } else {
                        this.getRemoteRegion(region.id);
                    }
                    break;
                }
            }
            if (!findNode) {
                this.setState({ selectedRegion: [] }, () => {
                    this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
                });
            }
        }
        if (level >= 2) {
            const { selectedRegion } = this.state;
            let findNode = false;
            for (let i = 0, iLen = selectedRegion[level - 2].children?.length; i < iLen; i++) {
                const region = selectedRegion[level - 2].children[i];
                if (region.value === value) {
                    const { data } = await this.getRegionList(this.state.selectedDistribution, region.level, region.id);
                    region.children = data;
                    selectedRegion.length = level - 1;
                    selectedRegion.push(region);
                    this.setState({ selectedRegion }, () => {
                        this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
                    });
                    findNode = true;
                    if (data?.length > 0) {
                        this.regionChangeHandler(data[0].value, level + 1);
                    } else {
                        this.getRemoteRegion(region.id);
                    }
                    break;
                }
            }
            if (!findNode) {
                const newSelectedRegion = selectedRegion.slice(0, level);
                newSelectedRegion[level - 1].children = [];
                this.setState({ selectedRegion: newSelectedRegion }, () => {
                    this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
                });
            }
        }
    };

    async getRemoteRegion(regionId: string) {
        if (!regionId) return;
        const promoteId = sessionStorage.getItem('promoteId');
        const { data } = await remoteRegion({ regionId, promoteId });
        this.setState({ remoteLogisticsFee: data.remoteLogisticsFee });
        this.props?.onRemoteRegion({ remoteLogisticsFee: data.remoteLogisticsFee, regionId });
    }

    triggerAddToCartFbq = () => {
        if (this.triggeredAddToCart) {
            return;
        }
        const { config, commodity } = this.state;
        const { selectedPackage } = this.props;
        this.triggeredAddToCart = true;
        //FB像素处理
        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq?.('track', 'AddToCart', { value: selectedPackage.price, currency: commodity.currencyCode });
                } catch (error) {}
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('AddToCart');
                } catch (error) {}
            }
        }
        if (config.hasLineAds) {
            if (window._lt) {
                try {
                    if (config.adsConfig.line?.tagId) {
                        window._lt('send', 'cv', { type: 'AddToCart' }, [config.adsConfig.line?.tagId]);
                    }
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelAddToCart) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelAddToCart,
                            value: selectedPackage.price,
                            currency: commodity.currencyCode,
                        });
                    }
                } catch (error) {}
            }
        }
    };

    async componentWillMount() {
        const commodity = JSON.parse(sessionStorage.getItem('commodity') as string);
        const { data } = await this.getRegionList(commodity?.distributionModeArr?.[0]);
        this.setState({ commodity, region: data }, () => {
            if (data?.[0]?.level >= 0) {
                this.regionChangeHandler(data?.[0]?.value, data?.[0]?.level);
            }
        });
    }
    componentDidMount() {
        this.triggerAddToCartFbq();
    }

    render() {
        const { config, commodity } = this.state;
        const { intl } = this.props;
        return (
            <div className="alizi-box" id="alizi-box-2">
                <div>
                    <div></div>
                </div>
                <div className="alizi-rows clearfix rows-id-name">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.name" />
                        <span className="alizi-request">*</span>
                    </label>
                    <div className="rows-params">
                        <input
                            type="text"
                            id="name0"
                            placeholder={intl?.formatMessage({
                                id: commodity.regionCode === 'JPN' ? 'fill.real.name0' : 'input.name',
                            })}
                            maxLength={50}
                            className="alizi-input-text"
                            style={{ width: commodity.regionCode === 'JPN' ? '46%' : '90%' }}
                            onBlur={event => {
                                this.changeHandler({ realName0: event.target.value });
                            }}
                        />
                        {commodity.regionCode === 'JPN' && (
                            <span>
                                <span>-</span>
                                <input
                                    type="text"
                                    id="name1"
                                    maxLength={50}
                                    placeholder={intl?.formatMessage({ id: 'fill.real.name1' })}
                                    className="alizi-input-text"
                                    style={{ width: '42%' }}
                                    onBlur={event => {
                                        this.changeHandler({ realName1: event.target.value });
                                    }}
                                />
                            </span>
                        )}
                    </div>
                </div>
                <div className="alizi-rows clearfix rows-id-mobile">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.phone" />
                        <span className="alizi-request">*</span>
                    </label>
                    <div className="rows-params">
                        <input
                            type="tel"
                            name="mobile"
                            id="mobile"
                            maxLength={100}
                            placeholder={
                                intl?.formatMessage({ id: 'input.phone' }) +
                                (commodity.regionCode == 'TWN' ? '(格式：09XXXXXXXX)' : '')
                            }
                            className="alizi-input-text"
                            alizi-request="1"
                            onBlur={event => {
                                this.changeHandler({ phone: event.target.value });
                            }}
                        />
                    </div>
                </div>
                <div className="alizi-rows clearfix rows-id-mobile">
                    <label className="rows-head">
                        <FormattedMessage id="email" />
                        {config?.requiredConfig?.shipEmail?.required && <span className="alizi-request">*</span>}
                    </label>
                    <div className="rows-params">
                        <input
                            name="shipEmail"
                            id="shipEmail"
                            maxLength={100}
                            placeholder={intl?.formatMessage({ id: 'input.email' })}
                            className="alizi-input-text"
                            alizi-request=""
                            onBlur={event => {
                                this.changeHandler({ shipEmail: event.target.value });
                            }}
                        />
                    </div>
                </div>
                <div className="alizi-rows clearfix rows-id-play">
                    <label className="rows-head">
                        <FormattedMessage id="pay.method" />
                        <span className="alizi-request alizi-request-none">*</span>
                    </label>
                    <div className="rows-params">
                        <div className="sex">
                            <div className="select fr">
                                <div className="male fl">
                                    <label>
                                        <input type="radio" name="play" />
                                    </label>
                                    <span className="btn active">
                                        <FormattedMessage id="cod" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alizi-rows clearfix rows-id-payment">
                    <label className="rows-head">
                        <FormattedMessage id="delivery.method" />
                        <span className="alizi-request">*</span>
                    </label>
                    <div className="rows-params">
                        <div className="sex">
                            <div className="select fr">
                                {commodity?.distributionModeArr?.map((dis: any) => {
                                    return (
                                        <div
                                            key={dis.label}
                                            className="male fl"
                                            onClick={this.changeDistributionHandler.bind(this, dis)}
                                        >
                                            <label>
                                                <input type="radio" name="payment" value="7" />
                                            </label>
                                            <span
                                                className={`btn ${
                                                    this.state.selectedDistribution.value === dis.value ? 'active' : ''
                                                }`}
                                            >
                                                {dis.label}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.region?.length > 0 && (
                    <div className="alizi-rows clearfix rows-id-region">
                        <label className="rows-head">
                            <FormattedMessage id="ship-info.select-region" />
                            <span className="alizi-request">*</span>
                        </label>
                        <div className="rows-params">
                            <select
                                style={{ maxWidth: '49%' }}
                                name="region[province]"
                                id="province"
                                className="alizi-region alizi-region-province"
                                alizi-request=""
                                onChange={e => {
                                    this.regionChangeHandler(e.target.value, 1);
                                }}
                            >
                                {this.state.region.map(reg => {
                                    return (
                                        <option key={reg.value} value={reg.value}>
                                            {reg.label}
                                        </option>
                                    );
                                })}
                            </select>
                            {this.state?.selectedRegion?.map((reg: any, index: number) => {
                                if (!reg?.children?.length) {
                                    return null;
                                }
                                return (
                                    <select
                                        key={reg.value + index}
                                        style={{ maxWidth: '49%' }}
                                        name="region[city]"
                                        id="city"
                                        className="alizi-region alizi-region-city"
                                        alizi-request=""
                                        onChange={e => {
                                            this.regionChangeHandler(e.target.value, index + 2);
                                        }}
                                    >
                                        {reg?.children.map((childReg: any) => {
                                            return (
                                                <option key={childReg.value} value={childReg.value}>
                                                    {childReg.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className="alizi-rows clearfix rows-id-address">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.detail-address" />
                        <span className="alizi-request">*</span>
                    </label>
                    <label>{this.state.selectedRegion.map(reg => (reg.value ? reg.label : '')).join(' ')}</label>
                    <span style={{ color: '#f60' }}>
                        {this.state.remoteLogisticsFee > 0
                            ? '(' +
                              intl?.formatMessage({ id: 'ship-info.remote_regions_fee' }) +
                              ':' +
                              this.state.remoteLogisticsFee +
                              ')'
                            : ''}
                    </span>
                    <div className="rows-params">
                        {!this.state.selectedDistribution.hideAddressInput && (
                            <textarea
                                id="address"
                                name="address"
                                maxLength={300}
                                placeholder={intl?.formatMessage({ id: 'input.detail-address' })}
                                alizi-request="1"
                                className="alizi-input-text"
                                onBlur={event => {
                                    this.changeHandler({ detailAddress: event.target.value?.trim() });
                                }}
                            />
                        )}
                    </div>
                </div>

                <div className="alizi-rows clearfix rows-id-post">
                    <label className="rows-head">
                        <FormattedMessage id="zip-code" />
                        {config?.requiredConfig?.shipZipCode?.required && <span className="alizi-request">*</span>}
                    </label>
                    <div className="rows-params">
                        <input
                            type="text"
                            id="shipZipCode0"
                            name="shipZipCode0"
                            alizi-request="1"
                            maxLength={30}
                            className="alizi-input-text"
                            placeholder={intl?.formatMessage({
                                id: commodity.regionCode === 'JPN' ? 'fill.zip.code0' : 'input.zip-code',
                            })}
                            style={{ width: commodity.regionCode === 'JPN' ? '46%' : '90%' }}
                            onBlur={event => {
                                this.changeHandler({ shipZipCode0: event.target.value });
                            }}
                        />
                        {commodity.regionCode === 'JPN' && (
                            <span>
                                <span>-</span>
                                <input
                                    type="text"
                                    id="shipZipCode1"
                                    name="shipZipCode1"
                                    alizi-request="1"
                                    maxLength={32}
                                    className="alizi-input-text"
                                    style={{ width: '42%' }}
                                    placeholder={intl?.formatMessage({
                                        id: 'fill.zip.code1',
                                    })}
                                    onBlur={event => {
                                        this.changeHandler({ shipZipCode1: event.target.value });
                                    }}
                                />
                            </span>
                        )}
                    </div>
                </div>
                <div className="alizi-rows clearfix rows-id-remark">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.message" />
                        <span className="alizi-request alizi-request-none">*</span>
                    </label>
                    <div className="rows-params">
                        <textarea
                            name="remark"
                            id="remark"
                            placeholder={intl?.formatMessage({ id: 'input.message' })}
                            className="alizi-input-text"
                            alizi-request=""
                            maxLength={300}
                            rows={3}
                            onBlur={event => {
                                this.changeHandler({ remark: event.target.value });
                            }}
                        ></textarea>
                    </div>
                </div>
                <div style={{ height: '20px' }}></div>
            </div>
        );
    }
}
