import Toast from './toast';

interface ToastConfig {
    duration?: number;
    autoClose?: boolean;
}

const fadeOutTime = 0.3;
let toastInstance: {
    type: string;
    tip: string;
    autoClose: boolean;
    duration: number;
    destroy: () => void;
};
let timer: any;
const getToastInstance = (tip: string, type: string, config?: ToastConfig) => {
    destroyToastInstance();
    toastInstance = Toast.newInstance({
        tip,
        type,
        ...config,
    });
    if (toastInstance.autoClose) {
        timer = setTimeout(() => {
            destroyToastInstance();
        }, (toastInstance.duration + fadeOutTime) * 1000);
    }
    return toastInstance;
};
const destroyToastInstance = () => {
    if (toastInstance) {
        clearTimeout(timer);
        toastInstance.destroy();
    }
};
export default {
    info(tip: string, config?: ToastConfig) {
        getToastInstance(tip, 'info', config);
    },
    success(tip: string, config?: ToastConfig) {
        getToastInstance(tip, 'success', config);
    },
    error(tip: string, config?: ToastConfig) {
        getToastInstance(tip, 'error', config);
    },
    close() {
        destroyToastInstance();
    },
};
