import { saveLog } from 'src/api';

export default class Logger {
    public static enabled = true; //process.env.NODE_ENV !== 'production';
    public static output: Array<'CONSOLE' | 'FILE' | 'NETWORK'> = ['FILE', 'NETWORK']; //CONSOLE:输出控制台,FILE:输出文件
    private context: any = null;
    private contextName: string = '';

    constructor(context: any) {
        this.context = context;
        if (typeof context === 'string') {
            this.contextName = context;
        } else {
            this.contextName = context.constructor.name;
        }
    }

    private formatLog(args: any[]) {
        const reg = /%o|%s/gi;
        let logContent = args[0];
        if (typeof logContent === 'object') {
            return JSON.stringify(logContent);
        }
        let index = 1;
        logContent = logContent.replace(reg, () => {
            return JSON.stringify(args[index++]);
        });
        return logContent;
    }
    private output(func: Function, args: any[], flag: string) {
        if (Logger.enabled) {
            func.call(console, `[C] [${flag}] [${new Date()}] [${this.contextName}] ${this.formatLog(args)}`);
        }
    }

    /**
     * 保存日志到网络
     * @param content 日志内容
     */
    private saveNetworkLog(content: string) {
        saveLog({ content });
    }

    public log(...args: any[]) {
        Logger.output.includes('CONSOLE') && this.output(console.log, args, 'LOG');
        Logger.output.includes('NETWORK') && this.output(this.saveNetworkLog, args, 'LOG');
    }
    public debug(...args: any[]) {
        Logger.output.includes('CONSOLE') && this.output(console.debug, args, 'DEBUG');
        Logger.output.includes('NETWORK') && this.output(this.saveNetworkLog, args, 'DEBUG');
    }
    public info(...args: any[]) {
        Logger.output.includes('CONSOLE') && this.output(console.info, args, 'INFO');
        Logger.output.includes('NETWORK') && this.output(this.saveNetworkLog, args, 'INFO');
    }
    public warn(...args: any[]) {
        Logger.output.includes('CONSOLE') && this.output(console.warn, args, 'WARN');
        Logger.output.includes('NETWORK') && this.output(this.saveNetworkLog, args, 'WARN');
    }
    public error(...args: any[]) {
        Logger.output.includes('CONSOLE') && this.output(console.error, args, 'ERROR');
        Logger.output.includes('NETWORK') && this.output(this.saveNetworkLog, args, 'ERROR');
    }
    public dir(...args: any[]) {
        Logger.output.includes('CONSOLE') && this.output(console.dir, args, 'DIR');
        Logger.output.includes('NETWORK') && this.output(this.saveNetworkLog, args, 'DIR');
    }
    public trace(...args: any[]) {
        Logger.output.includes('CONSOLE') && this.output(console.trace, args, 'TRACE');
        Logger.output.includes('NETWORK') && this.output(this.saveNetworkLog, args, 'TRACE');
    }
}
