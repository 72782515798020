/** 默认本地化语言 */
export const DEFAULT_LANGUAGE = 'zh-CN';

export const BusinessEvent = {
    /** 用户行为事件 */
    USER_ACTION: 'USER_ACTION_EVENT',
};

/**
 * 用户行为事件
 */
export const UserAction = {
    /** 进入商品页 */
    ENTRY_COMMODITY_PAGE: 'ENTRY_COMMODITY_PAGE',
    /** 浏览到底部 */
    BROWSE_TO_BOTTOM: 'BROWSE_TO_BOTTOM',
    /** 加入购物车(进入下单页) */
    ADD_TO_CART: 'ADD_TO_CART',
    /** 填写收件信息 */
    FILL_SHIP_INFO: 'FILL_SHIP_INFO',
    /** 提交订单 */
    SUBMIT_ORDER: 'SUBMIT_ORDER',
    /** 自动提交订单 */
    AUTO_SUBMIT_ORDER: 'AUTO_SUBMIT_ORDER',
};
