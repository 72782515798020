import React from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LANGUAGE } from '../common/constant';

const i18nMap: { [key: string]: any } = {
    'zh-CN': require('../i18n/zh-CN.json'),
    'zh-TW': require('../i18n/zh-TW.json'),
    'en-US': require('../i18n/en-US.json'),
    'ja-JP': require('../i18n/ja-JP.json'),
    'ru-RU': require('../i18n/ru-RU.json'),
    'th-TH': require('../i18n/th-TH.json'),
    'id-ID': require('../i18n/id-ID.json'),
    'pl-PL': require('../i18n/pl-PL.json'),
    'ko-KR': require('../i18n/ko-KR.json'),
    'ro-RO': require('../i18n/ro-RO.json'),
    'vi-VN': require('../i18n/vi-VN.json'),
};
const currentLangFullName = DEFAULT_LANGUAGE !== 'zh-CN' ? 'English' : '中文（简体）';
const { Provider, Consumer } = React.createContext({
    locale: DEFAULT_LANGUAGE,
    messages: {},
    switchLanguage: (lang: string, langTitle: string) => {},
    langFullName: currentLangFullName,
});

interface IntlProviderState {
    locale: string;
    messages: any;
    switchLanguage: (lang: string, langTitle: string) => void;
    langFullName: string;
}

class IntlProviderWrapper extends React.Component<{}, IntlProviderState> {
    constructor(props: any) {
        super(props);
        this.state = {
            locale: DEFAULT_LANGUAGE,
            messages: i18nMap[DEFAULT_LANGUAGE],
            switchLanguage: this.switchLanguage,
            langFullName: currentLangFullName,
        };
    }
    componentWillMount() {
        let { lang } = JSON.parse(sessionStorage.getItem('config') as string);
        this.switchLanguage(lang);
    }
    switchLanguage = (lang: string, langTitle: string = '') => {
        this.setState({
            locale: lang,
            messages: i18nMap[lang],
            langFullName: langTitle,
        });
    };
    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        return (
            <Provider value={this.state}>
                <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={DEFAULT_LANGUAGE}>
                    {children}
                </IntlProvider>
            </Provider>
        );
    }
}

export { IntlProviderWrapper, Consumer as IntlConsumer };
