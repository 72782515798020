import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import Base from 'src/core/base';
import { getOrder } from 'src/api/index';
import './index.scss';

interface Prop {}
interface State {
    order: any;
}

export default class OrderConfirm extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            order: {},
        };
    }

    loadOrder = async () => {
        const { match } = this.props;
        const { orderId } = match?.params ?? ({} as any);
        const { data } = await getOrder(orderId);
        this.setState({ order: data });
    };

    async componentWillMount() {
        const { match } = this.props;
        const { orderId } = match?.params ?? ({} as any);
        const { data } = await getOrder(orderId);
        this.setState({ order: data });
    }
    backHomeHandler = () => {
        this.props.history?.replace('/cod/detail');
    };
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { order } = this.state;
        return (
            <div className="result">
                <h1>
                    <img alt="success" src={require('src/assets/images/success.png')} />
                    <FormattedMessage id="submit-order-success-tip" />
                </h1>
                <div className="innner order_div success">
                    <div className="order" style={{ minHeight: 'calc(100vh - 244px)' }}>
                        <ul>
                            <li>
                                <label>
                                    <FormattedMessage id="order-number" />
                                </label>
                                <span id="order_no"> : {order?.code}</span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="order-success.combo" />
                                </label>
                                <span> : {order?.comboName}</span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="commodity" />
                                </label>
                                <span> : {order?.commodityName}</span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="order.detail" /> :
                                </label>
                                <br />
                                {order?.spuArr?.map((spu: any) => {
                                    return (
                                        <Fragment key={spu.spuName}>
                                            <span>{spu.spuName}</span>
                                            <br />
                                            {spu?.skuArr?.map((sku: any, index: number) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <span>
                                                            <FormattedMessage id="order.count" /> : {sku.qty}
                                                        </span>
                                                        <p>
                                                            {sku?.attributeValueArr?.map((attr: any) => {
                                                                if (attr.attributeValueLabel.indexOf('http') === 0) {
                                                                    return (
                                                                        <span key={attr.attributeValueLabel}>
                                                                            {attr.attributeName + ' : ' +
                                                                            (
                                                                                <img
                                                                                    src={attr.attributeValueLabel + '!thn90-90'}
                                                                                    alt=""
                                                                                />
                                                                            )}
                                                                        </span>
                                                                    );
                                                                }
                                                                return (
                                                                    <span key={attr.attributeValueLabel}>
                                                                        {attr.attributeName + ' : ' + attr.attributeValueLabel}
                                                                    </span>
                                                                );
                                                            })}
                                                        </p>
                                                    </Fragment>
                                                );
                                            })}
                                        </Fragment>
                                    );
                                })}
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="price" />
                                </label>
                                <span>
                                    : <b>{order?.totalPrice}</b>
                                </span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="pay.method" />
                                </label>
                                <span>
                                    : <FormattedMessage id="cod" />{' '}
                                </span>
                            </li>
                            {/* <li>
                                <label>
                                    <FormattedMessage id="delivery.method" />
                                </label>
                                <span>{order.distributionMode}</span>
                            </li> */}
                            <li>
                                <label>
                                    <FormattedMessage id="ship-info.name" />
                                </label>
                                <span>
                                    : <b>{order?.shipNickname}</b>
                                </span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="ship-info.phone" />
                                </label>
                                <span>
                                    : <b>{order?.shipPhone}</b>
                                </span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="email" />
                                </label>
                                <span> : {order?.shipEmail} </span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="ship-info.region" />
                                </label>
                                <span>
                                    : <b>{order?.shipState}</b>
                                </span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="ship-info.detail-address" />
                                </label>
                                <span> : {order?.shipFullAddress} </span>
                            </li>
                            <li>
                                <label>
                                    <FormattedMessage id="zip-code" />
                                </label>
                                <span> : {order?.shipZipCode} </span>
                            </li>

                            <li>
                                <label>
                                    <FormattedMessage id="ship-info.message" />
                                </label>
                                <span> : {order?.message} </span>
                            </li>
                        </ul>
                    </div>

                    <div style={{ color: 'red', textAlign: 'center', width: '100%', padding: '10px 0px' }}>
                        <FormattedMessage id="security.info" />
                    </div>
                    <div className="foot">
                        <a className="foot_btn" href="javascript:void(0);" onClick={this.backHomeHandler}>
                            <FormattedMessage id="go-back" />
                        </a>
                        <p></p>
                    </div>
                </div>
            </div>
        );
    }
}
