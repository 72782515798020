import { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WrappedComponentProps } from 'react-intl';

import EventBusManager from './event-bus-manager';
import Logger from 'src/utils/logger';

export default class Base<P = {}, S = {}> extends Component<
    P & Partial<WrappedComponentProps> & Partial<RouteComponentProps>,
    S
> {
    logger: Logger;
    constructor(props: any) {
        super(props);
        this.logger = new Logger(this);
    }

    dispatch(name: string, ...params: any[]) {
        EventBusManager.dispatch(name, ...params);
    }
    addEventListener(name: string, callback: (...params: any[]) => void, scope = window) {
        EventBusManager.add(name, callback, scope);
    }
    removeEventListener(name: string, callback: (...params: any[]) => void, scope = window) {
        EventBusManager.remove(name, callback, scope);
    }
    componentDidCatch(err: any) {
        this.logger.error('unknown error:', err);
    }
}
