import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import { getUserInstruction } from 'src/api/index';
// import Logger from 'src/utils/logger';
import { BusinessEvent, UserAction } from 'src/common/constant';
import UserActionDataModel from 'src/model/user-action-data-model';
import UserActionExtraModel from 'src/model/user-action-extra-model';
import 'swiper/swiper.scss';
import './index.scss';

// const logger = new Logger('detail');
SwiperCore.use([Autoplay]);

const ensureLogo = require('src/assets/images/sjbz.png');
interface Prop {}
interface State {
    commodity: any;
    config: any;
}

@injectIntl()
export default class Detail extends Base<Prop, State> {
    private userInstruction: any = null;
    private swiper: any = null;
    private ubBrowseToBottomSubmitted: boolean = false;
    constructor(props: any) {
        super(props);
        this.state = {
            commodity: {},
            config: {},
        };
    }

    private iniCommodity = async () => {
        const commodity = JSON.parse(sessionStorage.getItem('commodity') as string);

        if (!commodity) {
            return;
        }

        const config = JSON.parse(sessionStorage.getItem('config') as string);

        const userActionData: UserActionDataModel = {
            promoteId: config.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
            promoteFrom: document.referrer,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.ENTRY_COMMODITY_PAGE,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);

        if (!this.userInstruction && commodity.noticeId) {
            const { data } = await getUserInstruction(commodity.noticeId);
            this.userInstruction = data;
        }
        this.setState(
            {
                commodity,
                config,
            },
            () => {
                if (commodity?.imgArr?.length > 1) {
                    this.swiper?.update();
                }
            },
        );
    };

    initScrollHandler = () => {
        $(window).on('scroll', () => {
            const scrollTop = $(window).scrollTop();
            const scrollHeight = $(document).height();
            const windowHeight = $(window).height();
            if (scrollTop + windowHeight === scrollHeight) {
                //已提交，不再提交
                if (this.ubBrowseToBottomSubmitted) {
                    return;
                }
                this.ubBrowseToBottomSubmitted = true;
                const { commodity, config } = this.state;
                const userActionData: UserActionDataModel = {
                    promoteId: config.promoteId,
                    commodityId: commodity.id,
                    random: commodity.random,
                };
                const userActionExtra: UserActionExtraModel = {
                    type: UserAction.BROWSE_TO_BOTTOM,
                };
                this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
            }
        });
    };

    buyHandler = () => {
        this.props.history?.push({
            pathname: '/cod/order',
            state: { commodity: this.state.commodity },
        });
    };

    socialHandler = () => {
      const { config } = this.state;
      if (!config.socialType) return;
        switch (config.socialType.split(',')[0].toUpperCase()) {
            case 'FACEBOOK':
                window.open(`https://www.m.me/${config.socialNo.split(',')[0]}`);
                break;
            case 'WHATS_APP':
                window.open(`https://api.whatsapp.com/send?phone=${config.socialNo.split(',')[0]}`);
                break;
            case 'LINE':
                window.open(`https://line.me/ti/p/${config.socialNo.split(',')[0]}`);
                break;

            default:
                break;
        }
    };

    componentDidMount() {
        this.iniCommodity();
        this.initScrollHandler();
    }

    componentWillUnmount() {
        $(window).off('scroll');
    }

    //锚点
    scrollToAnchor = (anchorName: string) => {
      if (anchorName) {
        // 找到锚点
        let anchorElement = document.getElementById(anchorName);
        // 如果对应id的锚点存在，就跳转到锚点
        if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
      }
    }

    render() {
        const { commodity, config } = this.state;
        return (
            <div className="wrapper alizi-detail-wrap">
                <div className="header">
                    <h1>{this.state.commodity?.name}</h1>
                </div>
                <div className="alizi-page">
                    <div className="box-image">
                        {!commodity?.imgArr?.length || commodity?.imgArr?.length === 1 ? (
                            <img
                                src={commodity?.imgArr?.[0] ? commodity?.imgArr?.[0] : commodity?.mainImg}
                                className="image"
                                alt=""
                            />
                        ) : (
                            <Swiper
                                init={true}
                                loop={true}
                                autoplay={{ disableOnInteraction: false, delay: 2 * 1000 }}
                                onSwiper={swiper => (this.swiper = swiper)}
                            >
                                {commodity?.imgArr?.map((img: any) => {
                                    return (
                                        <SwiperSlide key={img}>
                                            <img src={img} className="image" alt="" />
                                        </SwiperSlide>
                                    );
                                })}
                            </Swiper>
                        )}
                    </div>
                    {/* <div className="service">
                        <div className="fship bb">
                            <span>免郵費</span>
                        </div>
                        <div className="cdeli bb">
                            <span>貨到付款</span>
                        </div>
                        <div className="shiwu bb">
                            <span>30天鑒賞期</span>
                        </div>
                        <div className="clear"></div>
                    </div> */}
                    <div className="buy">
                        <div className="title">
                            {/* <h1>{this.state.commodity?.name}</h1> */}
                            <div className="sales">
                                {/* {this.state.commodity?.salePrice && (
                                    <div className="price">
                                        {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' && commodity.currencyDisplay}
                                        <ins>{this.state.commodity?.salePrice}</ins>
                                        {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' && commodity.currencyDisplay}
                                    </div>
                                )} */}
                                <div className="sales_info">
                                    {/* {!!this.state.commodity?.originalPrice && (
                                        <div>
                                            <del>
                                                {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' &&
                                                    commodity.currencyDisplay}
                                                {this.state.commodity?.originalPrice}
                                                {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' &&
                                                    commodity.currencyDisplay}
                                            </del>
                                        </div>
                                    )} */}
                                    <div className="sales_num">
                                        <FormattedMessage
                                            id="count.sold"
                                            values={{ count: this.state.commodity?.soldCount ?? 0 }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <p className="boom">
                                <FormattedMessage id="detail.title" />
                            </p>
                        </div>

                        <div className="buy_now" onClick={this.buyHandler}>
                            <a className=" btnPay" href="javascript:void(0);">
                                <FormattedMessage id="buy-immediately.btn" />
                            </a>
                        </div>
                    </div>

                    <div className="box">
                        <div className="wttl">
                            <h2>
                                {' '}
                                <FormattedMessage id="commodity.attr" />
                            </h2>
                        </div>
                        <div
                            className="box-content alizi-detail-content "
                            dangerouslySetInnerHTML={{ __html: this.state.commodity?.description }}
                        ></div>
                    </div>
                    {commodity.noticeId && (
                        <div
                            style={{
                                paddingBottom: '10px',
                                borderBottom: '1px solid #ddd',
                                fontSize: '1.2rem',
                                textAlign: 'center',
                            }}
                        >
                            <span>//</span>
                            <FormattedMessage id="customer-instructions.title" />
                            <span>//</span>
                        </div>
                    )}
                    <div style={{ background: '#fff', height: '100%' }}>
                        <div className="bellows single">
                            {this.userInstruction?.itemArr?.map((item: any) => {
                                return (
                                    <div
                                        key={item.title}
                                        className="bellows__item"
                                        onClick={e => {
                                            const target = $(e.currentTarget);
                                            const wrapper = target.find('.bellows__content-wrapper');
                                            if (wrapper.css('display') === 'none') {
                                                wrapper.toggle(200, () => {
                                                    target.addClass('bellows--is-open');
                                                });
                                            } else {
                                                wrapper.toggle(200, () => {
                                                    target.removeClass('bellows--is-open');
                                                });
                                            }
                                        }}
                                    >
                                        <div className="bellows__header">{item.title}</div>
                                        <div className="bellows__content-wrapper">
                                            <div
                                                className="bellows__content"
                                                dangerouslySetInnerHTML={{ __html: item.content }}
                                            ></div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ textAlign: 'center', margin: '5px', fontSize: '74%' }}>
                            <img src={ensureLogo} alt="logo" style={{ width: '40px' }} />
                            <p></p>
                            <span style={{ fontSize: '130%' }}>
                                <FormattedMessage id="user.ensure" />
                            </span>
                            <p></p>
                            <FormattedMessage id="all.money.returned" />
                            <p></p>
                            <FormattedMessage id="part.money.returned" />
                            <p></p>
                            @2020-2022 . All rights reserved
                        </div>
                    </div>
                    <div className="box"></div>
                </div>
                <div className="alizi-footer"></div>

                <div className="alizi-foot-nav" style={{ zIndex: 999 }}>
                    <a
                        className="alizi-up"
                        href="javascript:void(0);"
                        onClick={() => {
                            window.scrollTo(0, 0);
                        }}
                    >
                        TOP
                    </a>
                    <ul>
                        <li className="foot-nav-1" style={{ width: config?.socialNo ? '25%' : '49%' }}>
                            <a
                                href="javascript:void(0);"
                                onClick={() => {
                                    this.props.history?.push('/cod/search-order');
                                }}
                            >
                                <strong className="">
                                    <FormattedMessage id="query-order" />
                                </strong>
                            </a>
                        </li>
                        {/* {config?.socialNo && (
                            <li className="foot-nav-1" style={{ width: '24%' }}>
                                <a onClick={this.socialHandler}>
                                    <strong className="">
                                        <FormattedMessage id="detail.online_chat" />
                                    </strong>
                                </a>
                            </li>
                )} */}
                {/* {config?.socialNo && (<select
                                style={{ width: '24%' }}
                                name="region[province]"
                                id="province"
                                className="alizi-region alizi-region-province"
                                alizi-request=""
                                onChange={e => {
                                    this.regionChangeHandler(e.target.value, 1);
                                }}
                            >
                                {this.state.region.map(reg => {
                                    return (
                                        <option key={reg.value} value={reg.value}>
                                            {reg.label}
                                        </option>
                                    );
                                })}
                            </select>
                  )} */}
                        <li className="foot-nav-2" style={{ width: '49%' }}>
                            <a onClick={this.buyHandler} href="javascript:void(0);">
                                <strong className="">
                                    <FormattedMessage id="buy-immediately.btn" />
                                </strong>
                            </a>
                        </li>
                    </ul>
            </div>
            
            <div className="fixedbtn">
              {config.socialType?.split(',').map((socialType:string,index:number) => {
                                    return (
                        socialType == 'FACEBOOK'  ? 
                   
                        <div key='FACEBOOK'
                            className="s_order"
                            style={{
                                background: `url(${require('src/assets/images/FACEBOOK.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank" href={`https://www.m.me/${config.socialNo.split(',')[index]}`}></a>
                        </div>
                   :socialType.toUpperCase() == 'LINE'  ? 
                        <div  key='LINE'
                            className="s_order"
                            style={{
                                background: `url(${require('src/assets/images/LINE1.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank" href={`https://line.me/ti/p/${config.socialNo.split(',')[index]}`}></a>
                        </div>
                     :socialType == 'WHATS_APP'  ? 
                        <div  key='WHATS_APP'
                            className="s_order"
                            style={{
                                background: `url(${require('src/assets/images/WHATS_APP.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank" href={`https://api.whatsapp.com/send?phone=${config.socialNo.split(',')[index]}`}></a>
                        </div>
                     :socialType == 'PHONE'  ? 
                        <div key='PHONE'
                                                className="s_order"
                                                title={config.socialNo.split(',')[index]}
                            style={{
                                background: `url(${require('src/assets/images/PHONE.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank"  href={`tel:${config.socialNo.split(',')[index]}`}></a>
                                              </div>
                                              :socialType == 'EMAIL'  ? 
                                              <div key='EMAIL'
                                                className="s_order"
                                                title={config.socialNo.split(',')[index]}
                            style={{
                                background: `url(${require('src/assets/images/EMAIL.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank"  href={`mailto:${config.socialNo.split(',')[index]}`}></a>
                                                </div>:<div key='none'/>
                                  );
                                })}
                </div>
            </div>
        );
    }
}
