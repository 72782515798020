import request from 'src/service/http-service';

/**
 * 根据父ID获取区域列表
 * @param data 
    {
        "promoteId":"", // 推广ID
        "parentId":null,//父ID，可以为空
        "regionCode":"TWN", //区域
        "distributionMode":"TWN_HEIMAO_XINZU" //配送方式
    }
 */
export const getRegionList = (data: any) =>
    request({
        url: `/v1/region/list`,
        method: 'post',
        data,
    });
