import React from 'react';
import ReactDOM from 'react-dom';
import './toast.css';

interface Prop {
    /** Toast类型 */
    type?: string;
    /** 提示内容 */
    tip?: string;
    /** 是否自动关闭 */
    autoClose?: boolean;
    /** 打开自动关闭的话，延迟几秒关闭 */
    duration?: number;
}

interface State {
    destroy: boolean;
}

class Toast extends React.Component<Prop, State> {
    private timer!: any;

    constructor(props: any) {
        super(props);
        this.state = {
            destroy: false,
        };
    }
    static defaultProps = {
        type: '',
        tip: '',
        duration: 3,
        autoClose: true,
    };
    static newInstance = (props: Prop) => {
        props = Object.assign({}, Toast.defaultProps, props);
        const { duration = 3, type = '', tip = '', autoClose = true } = props;
        let toastNode: HTMLDivElement | null = document.createElement('div');
        toastNode.className = 'toast-root';
        window.document.body.appendChild(toastNode);
        ReactDOM.render(<Toast {...props} />, toastNode);
        return {
            type,
            tip,
            autoClose,
            duration,
            destroy() {
                if (toastNode) {
                    ReactDOM.unmountComponentAtNode(toastNode!);
                    window.document.body.removeChild(toastNode!);
                    toastNode = null;
                }
            },
        };
    };
    componentDidMount() {
        const { duration, autoClose } = this.props;
        if (autoClose) {
            this.timer = setTimeout(() => {
                this.setState({ destroy: true });
            }, duration! * 1000);
        }
    }
    componentWillUnmount() {
        clearTimeout(this.timer);
    }
    render() {
        const { tip } = this.props;
        const { destroy } = this.state;
        const fadeOut = destroy ? 'fadeout' : '';
        return (
            <div className={`cod-toast ${fadeOut}`}>
                <div className="cod-toast__container">
                    <div className="cod-toast__content">
                        <span className="cod-toast__text">{tip}</span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Toast;
