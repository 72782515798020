import React from 'react';
import Base from 'src/core/base';

interface Prop {
    commodity: any;
    selectedPackage: any;
}
interface State {}

export default class CommodityInfo extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {}

    render() {
        const { commodity, selectedPackage } = this.props;
        return (
            <>
                <div className="goods_img" style={{ display: 'flex' }}>
                    <div className="pic" style={{ width: '100px', minWidth: '100px', height: '100px' }}>
                        <img
                            alt="主图"
                            src={selectedPackage?.imgUrl ? selectedPackage?.imgUrl : commodity?.mainImg}
                            style={{ width: '100px', height: '100px' }}
                        />
                    </div>
                    <div className="pic_str">
                        {commodity?.name}
                        <div style={{ marginTop: '10px' }}></div>
                    </div>
                </div>
            </>
        );
    }
}
