/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import { createOrder } from 'src/api/index';
import FooterBar from './components/footer-bar';
import SpuAttr from './components/spu-attr';
import Package from './components/package';
import CommodityInfo from './components/commodity-info';
import Toast from 'src/components/toast';
import Logger from 'src/utils/logger';

import './index.scss';
import UserActionDataModel from 'src/model/user-action-data-model';
import UserActionExtraModel from 'src/model/user-action-extra-model';
import { UserAction, BusinessEvent } from 'src/common/constant';

const logger = new Logger('Order');

interface Prop {}
interface State {
    config: any;
    commodity: any;
    selectedPackage: any;
    selectedSpuArr: any[];
    shipInfo: any;
    orderId: string | null;
    region: any;
}

@injectIntl()
export default class Index extends Base<Prop, State> {
    /** 是否正在静默提交 */
    private silentSubmitting: boolean = false;
    /** 是否有静默提交过了 */
    private silentSubmitted: boolean = false;
    /** 正在提交订单，防止重复提交 */
    private submittingOrder: boolean = false;
    //完成注册的FBQ已提交
    private completeRegistrationFbqSubmitted: boolean = false;

    private zipCodeRegExp: RegExp = /^\d+-?\d*$/;
    private phoneRegExp: RegExp = /^09[0-9]{8}$/;
    private mailRegExp: RegExp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    constructor(props: any) {
        super(props);
        this.state = {
            config: {},
            commodity: {},
            selectedPackage: {},
            selectedSpuArr: [],
            shipInfo: {},
            orderId: null,
            region: { remoteLogisticsFee: 0 },
        };
    }

    packageChangeHandler = (com: any) => {
        com.logisticsFee = com.logisticsFee ?? 0;
        this.setState({ selectedPackage: com });
    };

    changeSkuHandler = (spuArr: any[]) => {
        this.setState({ selectedSpuArr: spuArr });
    };

    shipInfoChangeHandler = (info: any) => {
        this.setState({ shipInfo: info }, () => {
            const validateResult = this.validateOrder();
            if (!validateResult.result) {
                return;
            }
            this.triggerCompleteRegistration(info);
        });
    };

    onRemoteRegionHandler = (region: any) => {
        this.setState({ region });
    };

    triggerCompleteRegistration = (shipInfo: any) => {
        const { commodity, selectedPackage, config } = this.state;
        //已提交过完成注册的fbq，不再提交
        if (this.completeRegistrationFbqSubmitted) {
            return;
        }
        this.completeRegistrationFbqSubmitted = true;
        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq('track', 'CompleteRegistration', {
                        value: selectedPackage.price + selectedPackage.logisticsFee,
                        currency: commodity.currencyCode,
                    });
                } catch (error) {}
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('CompleteRegistration');
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelCompleteRegistration) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelCompleteRegistration,
                            value: selectedPackage.price + selectedPackage.logisticsFee,
                            currency: commodity.currencyCode,
                        });
                    }
                } catch (error) {}
            }
        }

        this.ubFillShipInfo();
        this.ubAutoSubmitOrderInfo(shipInfo);
    };

    ubSubmitOrder = async (orderId: string) => {
        //用户行为(提交订单)处理
        const { commodity } = this.state;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
            orderId,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.SUBMIT_ORDER,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    ubFillShipInfo = async () => {
        //用户行为(填写收件信息)处理
        const { commodity } = this.state;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.FILL_SHIP_INFO,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    ubAutoSubmitOrderInfo = async (shipInfo: any) => {
        //用户行为(自动提交订单)处理
        const { commodity, selectedPackage, selectedSpuArr, config, region } = this.state;
        let shipHouse = '';
        for (let i = 3, iLen = shipInfo?.selectedRegion?.length; i < iLen; i++) {
            const reg = shipInfo?.selectedRegion[i];
            shipHouse += reg.value;
        }
        shipHouse += shipInfo.detailAddress;
        const orderInfo: any = {
            promoteId: commodity?.promoteId, // 推广id
            commodityId: commodity?.id,
            commodityName: this.state.commodity.name,
            commodityVer: commodity?.ver, // 商品版本,商品详细里返回的，提交订单倒提交
            comboId: selectedPackage?.id, //套餐id
            comboName: selectedPackage?.name, //套餐名称
            price: selectedPackage.price + selectedPackage.logisticsFee + region.remoteLogisticsFee,
            currencyCode: commodity.currencyCode,
            qty: 1,
            shipNickname: shipInfo?.realName,
            shipPhone: shipInfo?.phone,
            shipEmail: shipInfo?.shipEmail,
            shipState: shipInfo?.selectedRegion?.[0]?.value ?? '',
            shipCity: shipInfo?.selectedRegion?.[1]?.value ?? '',
            shipStreet: shipInfo?.selectedRegion?.[2]?.value ?? '',
            shipHouse: shipHouse,
            distributionMode: shipInfo?.selectedDistribution,
            distributionModeCode: shipInfo.selectedDistribution.value,
            shipZipCode: shipInfo?.shipZipCode,
            message: shipInfo?.remark,
            token: sessionStorage.getItem('token'),
            regionId: region.remoteLogisticsFee > 0 ? region.regionId : '',
        };
        orderInfo.spuArr = selectedSpuArr;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
            orderInfo,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.AUTO_SUBMIT_ORDER,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    /** 静默提交处理器 */
    silentSubmitOrder = async (shipInfo: any) => {
        const { commodity, selectedPackage, selectedSpuArr, config, orderId, region } = this.state;

        if (!config?.autoSubmit) {
            return;
        }
        if (this.silentSubmitting) {
            return;
        }

        //有静默提交过，且没有订单ID的也直接返回
        if (this.silentSubmitted && !orderId) {
            return;
        }
        this.silentSubmitting = true;
        let shipHouse = '';
        for (let i = 3, iLen = shipInfo?.selectedRegion?.length; i < iLen; i++) {
            const reg = shipInfo?.selectedRegion[i];
            shipHouse += reg.value;
        }
        shipHouse += shipInfo.detailAddress;
        const order: any = {
            id: orderId,
            promoteId: commodity?.promoteId, // 推广id
            commodityId: commodity?.id,
            commodityVer: commodity?.ver, // 商品版本,商品详细里返回的，提交订单倒提交
            comboId: selectedPackage?.id, //套餐id
            qty: 1,
            shipNickname: shipInfo?.realName,
            shipPhone: shipInfo?.phone,
            shipEmail: '',
            shipState: shipInfo?.selectedRegion?.[0]?.value ?? '',
            shipCity: shipInfo?.selectedRegion?.[1]?.value ?? '',
            shipStreet: shipInfo?.selectedRegion?.[2]?.value ?? '',
            shipHouse: shipHouse,
            distributionMode: shipInfo?.selectedDistribution,
            distributionModeCode: shipInfo.selectedDistribution.value,
            shipZipCode: shipInfo?.shipZipCode,
            message: shipInfo?.remark,
            customerApproved: false,
            token: sessionStorage.getItem('token'),
            regionId: region.remoteLogisticsFee > 0 ? region.regionId : '',
        };
        order.spuArr = selectedSpuArr;
        const { data } = await createOrder(order).catch();
        if (!this.silentSubmitted) {
            this.silentSubmitted = true;
        }
        this.setState({ orderId: data.orderId }, () => {
            this.silentSubmitting = false;
        });
    };

    submitOrderHandler = async () => {
        const validateResult = this.validateOrder();
        if (!validateResult.result) {
            Toast.info(validateResult.message);
            return;
        }
        let shipHouse = '';
        for (let i = 3, iLen = this.state.shipInfo?.selectedRegion?.length; i < iLen; i++) {
            const reg = this.state.shipInfo?.selectedRegion[i];
            shipHouse += reg.value;
        }
        shipHouse += this.state.shipInfo.detailAddress;
        const order: any = {
            id: this.state.orderId,
            promoteId: this.state.commodity.promoteId, // 推广id
            commodityId: this.state.commodity.id,
            commodityVer: this.state.commodity.ver, // 商品版本,商品详细里返回的，提交订单倒提交
            comboId: this.state.selectedPackage.id, //套餐id
            qty: 1,
            shipNickname: this.state.shipInfo.realName,
            shipPhone: this.state.shipInfo.phone,
            shipEmail: this.state.shipInfo.shipEmail,
            shipState: this.state.shipInfo.selectedRegion?.[0]?.value ?? '',
            shipCity: this.state.shipInfo.selectedRegion?.[1]?.value ?? '',
            shipStreet: this.state.shipInfo.selectedRegion?.[2]?.value ?? '',
            shipHouse: shipHouse,
            distributionMode: this.state.shipInfo.selectedDistribution,
            distributionModeCode: this.state.shipInfo.selectedDistribution.value,
            shipZipCode: this.state.shipInfo?.shipZipCode,
            message: this.state.shipInfo.remark,
            customerApproved: true,
            token: sessionStorage.getItem('token'),
            regionId: this.state.region.remoteLogisticsFee > 0 ? this.state.region.regionId : '',
        };
        order.spuArr = this.state.selectedSpuArr;
        if (this.submittingOrder) {
            return;
        }
        this.submittingOrder = true;
        await createOrder(order)
            .then(res => {
                if (res.data.orderId) {
                    logger.debug('提交订单成功。%o', res.data);
                    this.triggerPurchaseAds(res.data.orderId);
                    this.ubSubmitOrder(res.data.orderId);
                    this.props.history?.replace({
                        pathname: `/cod/order-confirm/${res.data.orderId}`,
                    });
                }
                this.submittingOrder = false;
            })
            .catch(res => {
                this.submittingOrder = false;
            });
    };

    triggerPurchaseAds = (orderId: string) => {
        const { commodity, selectedPackage, config } = this.state;

        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq?.('track', 'Purchase', {
                        value: selectedPackage.price + selectedPackage.logisticsFee,
                        currency: commodity.currencyCode,
                        orderId,
                    });
                } catch (error) {}
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('PlaceAnOrder');
                    window.ttq.track('CompletePayment');
                } catch (error) {}
            }
        }
        if (config.hasLineAds) {
            if (window._lt) {
                try {
                    if (config.adsConfig.line?.tagId) {
                        window._lt(
                            'send',
                            'cv',
                            {
                                type: 'Purchase',
                            },
                            [config.adsConfig.line?.tagId],
                        );
                        window._lt(
                            'send',
                            'cv',
                            {
                                type: 'Conversion',
                            },
                            [config.adsConfig.line?.tagId],
                        );
                    }
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelPurchase) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelPurchase,
                            value: selectedPackage.price + selectedPackage.logisticsFee,
                            currency: commodity.currencyCode,
                        });
                    }
                } catch (error) {}
            }
        }
    };
    validateOrder = () => {
        const intl = this.props.intl as any;
        const { shipInfo, config } = this.state;
        const { selectedRegion } = shipInfo;
        if (!shipInfo.realName) {
            return { result: false, message: intl.formatMessage({ id: 'name.empty' }) };
        }
        if (!shipInfo.phone) {
            return { result: false, message: intl.formatMessage({ id: 'phone.empty' }) };
        }

        if (this.state.commodity.regionCode == 'TWN' && shipInfo.phone && !this.phoneRegExp.test(shipInfo.phone)) {
            return { result: false, message: intl.formatMessage({ id: 'input.phone' }) + '(格式：09XXXXXXXX)' };
        }

        //下拉框有多个，并且最后一个下拉框没有选中
        if (selectedRegion?.length > 0 && selectedRegion[selectedRegion.length - 1]?.children?.length > 0) {
            return { result: false, message: intl.formatMessage({ id: 'address.empty' }) };
        }
        let noSelected = false;
        for (let i = 0, iLen = selectedRegion?.length; i < iLen; i++) {
            const reg = selectedRegion[i];
            if (!reg.value?.trim()) {
                noSelected = true;
                break;
            }
        }
        if (noSelected) {
            return { result: false, message: intl.formatMessage({ id: 'address.empty' }) };
        }

        //没有填写详细地址
        if (!shipInfo.detailAddress) {
            //显示详细地址框
            if (!shipInfo.selectedDistribution.hideAddressInput) {
                return { result: false, message: intl.formatMessage({ id: 'address.empty' }) };
            }
            //没有下拉选中地址
            if (!selectedRegion?.length) {
                return { result: false, message: intl.formatMessage({ id: 'address.empty' }) };
            }
        }

        if (config.requiredConfig['shipEmail']?.required && !shipInfo.shipEmail) {
            return { result: false, message: intl.formatMessage({ id: 'input.email' }) };
        }

        //input-wrong-email
        if (config.requiredConfig['shipEmail']?.required && shipInfo.shipEmail && !this.mailRegExp.test(shipInfo.shipEmail)) {
            return { result: false, message: intl.formatMessage({ id: 'input-wrong-email' }) };
        }

        if (config.requiredConfig['shipZipCode']?.required && !shipInfo.shipZipCode) {
            return { result: false, message: intl.formatMessage({ id: 'input.zip-code' }) };
        }

        //input-wrong-zipcode
        if (
            config.requiredConfig['shipZipCode']?.required &&
            shipInfo.shipZipCode &&
            !this.zipCodeRegExp.test(shipInfo.shipZipCode)
        ) {
            return { result: false, message: intl.formatMessage({ id: 'input-wrong-zipcode' }) };
        }

        return { result: true, message: '' };
    };

    addToCarHandler = () => {
        //用户行为(加入购物车,进入下单页)处理
        const { commodity } = this.state;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.ADD_TO_CART,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    componentWillMount() {
        let config = JSON.parse(sessionStorage.getItem('config') as string);
        this.setState({ config });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let commodity = (this.props?.location?.state as any)?.commodity;
        if (!commodity) {
            commodity = JSON.parse(sessionStorage.getItem('commodity') as string);
            if (!commodity) {
                this.props.history?.goBack();
                return;
            }
        }
        this.setState({ commodity }, () => {
            this.addToCarHandler();
        });
    }

    render() {
        const { commodity, selectedPackage } = this.state;

        let ShipInfoComponent: string = '';
        if (commodity.regionCode === 'POL') {
            ShipInfoComponent = '/pl';
        }
        const ShipInfo = require('./components/ship-info' + ShipInfoComponent).default;
        return (
            <div className="alizi-order-wrap clearfix">
                <div className="title alizi-border ellipsis">
                    <a
                        className="title-back"
                        href="javascript:void(0);"
                        onClick={() => {
                            this.props.history?.push('/cod/detail');
                        }}
                    />
                    <FormattedMessage id="order-confirmation.title" />
                </div>
                <div className="alizi-order alizi-theme-thin alizi-lang-zh-tw alizi-border clearfix" id="aliziOrder">
                    <div className="alizi-main alizi-border alizi-full-row">
                        <div className="alizi-content alizi-border">
                            <div className="alizi-box" id="alizi-box-1">
                                <CommodityInfo commodity={this.state.commodity} selectedPackage={this.state.selectedPackage} />
                                <Package commodity={this.state.commodity} onChangePackage={this.packageChangeHandler} />
                                <div className="alizi-box" id="alizi-box-2">
                                    <div className="alizi-rows clearfix rows-id-price">
                                        <div className="rows-params" style={{ textAlign: 'right' }}>
                                            <FormattedMessage id="combo.price" />：
                                            {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' &&
                                                commodity.currencyDisplay + ' '}
                                            <strong className="alizi-total-price">{this.state.selectedPackage.price}</strong>
                                            {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' &&
                                                ' ' + commodity.currencyDisplay}
                                        </div>
                                    </div>
                                    <div className="alizi-rows clearfix rows-id-price">
                                        <div className="rows-params" style={{ textAlign: 'right' }}>
                                            <FormattedMessage id="freight-charge" />：
                                            {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' &&
                                                commodity.currencyDisplay + ' '}
                                            <strong className="alizi-total-price">
                                                {this.state.selectedPackage.logisticsFee + this.state.region.remoteLogisticsFee}
                                            </strong>
                                            {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' &&
                                                ' ' + commodity.currencyDisplay}
                                        </div>
                                    </div>
                                    <div className="alizi-rows clearfix rows-id-price">
                                        <div className="rows-params" style={{ textAlign: 'right' }}>
                                            <FormattedMessage id="total.price" />：
                                            {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' &&
                                                commodity.currencyDisplay + ' '}
                                            <strong className="alizi-total-price">
                                                {this.state.selectedPackage.price +
                                                    this.state.selectedPackage.logisticsFee +
                                                    this.state.region.remoteLogisticsFee}
                                            </strong>
                                            {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' &&
                                                ' ' + commodity.currencyDisplay}
                                        </div>
                                    </div>
                                </div>
                                <SpuAttr
                                    commodity={this.state.commodity}
                                    selectedPackage={this.state.selectedPackage}
                                    onChangeSku={this.changeSkuHandler}
                                />
                            </div>
                            <ShipInfo
                                selectedPackage={selectedPackage}
                                onShipInfoChange={this.shipInfoChangeHandler}
                                onRemoteRegion={this.onRemoteRegionHandler}
                            />
                            <FooterBar
                                money={
                                    this.state.selectedPackage.price +
                                    this.state.selectedPackage.logisticsFee +
                                    this.state.region.remoteLogisticsFee
                                }
                                onSubmit={this.submitOrderHandler}
                            />
                        </div>
                    </div>
                    <div className="shopcar-foot"></div>
                </div>
            </div>
        );
    }
}
