import EventBus from './event-bus';

export default class EventBusManager {
    private static eventBus: EventBus = new EventBus();

    public static add(name: string, callback: (...params: any[]) => void, scope = window) {
        EventBusManager.eventBus.addEventListener(name, callback, scope);
    }

    public static dispatch(name: string, ...data: any[]) {
        EventBusManager.eventBus.dispatch(name, data);
    }

    public static remove(name: string, callback: (...params: any[]) => void, scope = window) {
        EventBusManager.eventBus.removeEventListener(name, callback, scope);
    }
}
