import React from 'react';
import { FormattedMessage } from 'react-intl';

import Base from 'src/core/base';
import request from 'src/service/http-service';
import './index.scss';

const DEFAULT_UPLOAD_IMAGE = require('src/assets/images/default-upload.jpg');

interface Prop {
    commodity: any;
    selectedPackage: any;
    onChangeSku: (selectedSpuArr: any[]) => void;
}
interface State {
    expands: boolean[];
    selectedSpuArr: any[];
}

export default class SpuAttr extends Base<Prop, State> {
    constructor(props: Prop) {
        super(props);
        this.state = {
            expands: [],
            selectedSpuArr: [],
        };
    }

    // static getDerivedStateFromProps(nextProps: Prop, prevState: State) {
    //     const { total } = nextProps;
    //     if (total !== prevState.expands.length) {
    //         const expands = new Array(total);
    //         for (let i = 0; i < total; i++) {
    //             expands[i] = true;
    //         }
    //         return {
    //             expands,
    //         };
    //     }
    //     // 否则，对于state不进行任何操作
    //     return null;
    // }

    toggleHandler = (index: number) => {
        const { expands } = this.state;
        expands[index] = !expands[index];
        this.setState({ expands });
    };

    changeSkuHandler = (spuIndex: number, productIndex: number, skuIndex: number, valueLabel: string) => {
        const { selectedSpuArr } = this.state;
        selectedSpuArr[spuIndex].skuArr[productIndex].attributeValueArr[skuIndex].attributeValueLabel = valueLabel;
        this.setState({ selectedSpuArr });
        this.props?.onChangeSku(selectedSpuArr);
    };

    /**
     * 属性是文本框的处理器
     * @param e
     * @param index
     */
    attrInputChangeHandler = (e: any, index: { spuIndex: number; productIndex: number; skuIndex: number }) => {
        this.changeSkuHandler(index.spuIndex, index.productIndex, index.skuIndex, e.target.value);
    };

    /**
     * 上传图片
     */
    uploadImageHandler = async (e: any, index: { spuIndex: number; productIndex: number; skuIndex: number }) => {
        const commodity = JSON.parse(sessionStorage.getItem('commodity') as string);
        const f = e.target;
        console.dir(f.files[0]);
        let param = new FormData();
        param.append('file', f.files[0]); //通过append向form对象添加数据
        param.append('commodityId', commodity.id);
        console.log(param.get('file')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        }; //添加请求头

        request.post(`/c/order/uploadImage`, param, config).then(response => {
            this.changeSkuHandler(index.spuIndex, index.productIndex, index.skuIndex, response.data.imgUrl);
            $(f.previousElementSibling).attr('src', response.data.imgUrl + '!thn90-90');
        });
    };

    renderItems = () => {
        const items = [];
        const { expands } = this.state;
        const { selectedPackage, commodity } = this.props;
        for (let i = 0, iLen = selectedPackage?.spuArr?.length ?? 0; i < iLen; i++) {
            const spuName = selectedPackage.spuArr[i].name;
            const amount = selectedPackage.spuArr[i].amount;
            let spuAttrArr = commodity.spuArr.filter((spu: any) => {
                return spuName === spu.name;
            });
            let sizeImgUrl = spuAttrArr[0].sizeImgUrl;
            spuAttrArr = spuAttrArr[0].attributeArr;
            for (let j = 0; j < amount; j++) {
                if (!spuAttrArr?.length) {
                    continue;
                }
                items.push(
                    <li key={`spu-attr-${i}+${j}`}>
                        <div
                            className={`con_ul_title ${expands[i + j] ? 'act' : ''}`}
                            onClick={this.toggleHandler.bind(this, i + j)}
                        >
                            <FormattedMessage id="spu-attr-title" values={{ spuName: spuName, index: j + 1 }} />
                        </div>
                        <div className="con" style={{ display: expands[i + j] ? 'block' : 'none' }}>
                            {spuAttrArr?.map((spuAttr: any, skuIndex: number) => {
                                return (
                                    <div className="alizi-rows clearfix rows-id-extends" key={`skuIndex${skuIndex}`}>
                                        <label className="rows-head" style={{ width: '100%' }}>
                                            <span className="alizi-request">*</span>
                                            {spuAttr.name}
                                        </label>
                                        <div className="rows-params">
                                            {spuAttr?.valueArr?.map((val: any, valIndex: number) => {
                                                return (
                                                    <label
                                                        key={val.label}
                                                        className={`alizi-group ${
                                                            spuAttr?.type === 'TEXT' ? '' : 'alizi-params'
                                                        } alizi-radio ${
                                                            this.state.selectedSpuArr?.[i]?.skuArr[j]?.attributeValueArr[skuIndex]
                                                                ?.attributeValueLabel === val.label
                                                                ? 'active'
                                                                : ''
                                                        }`}
                                                        onClick={this.changeSkuHandler.bind(this, i, j, skuIndex, val.label)}
                                                    >
                                                        {val.imgUrl && (
                                                            <>
                                                                <img src={val.imgUrl} className="sku-img" alt="" />
                                                                <br />
                                                            </>
                                                        )}
                                                        {spuAttr?.type === 'RADIO' && (
                                                            <>
                                                                <span className="alizi-group-box">
                                                                    <input
                                                                        type={spuAttr?.type?.toLocaleLowerCase()}
                                                                        defaultValue={val.label}
                                                                    />
                                                                    <label className="selected-icon"></label>
                                                                </span>
                                                                {val.label}
                                                                <i></i>
                                                            </>
                                                        )}
                                                        {spuAttr?.type === 'TEXT' && (
                                                            <>
                                                                <span className="alizi-group-box">
                                                                    <input
                                                                        type="text"
                                                                        placeholder={val.label}
                                                                        style={{ width: '100%' }}
                                                                        className="alizi-input-text"
                                                                        onChange={e => {
                                                                            this.attrInputChangeHandler(e, {
                                                                                spuIndex: i,
                                                                                productIndex: j,
                                                                                skuIndex,
                                                                            });
                                                                        }}
                                                                    />
                                                                    <label className="selected-icon"></label>
                                                                </span>
                                                                <i></i>
                                                            </>
                                                        )}
                                                        {spuAttr?.type === 'IMAGE' && (
                                                            <>
                                                                <div className="alizi-group-box">
                                                                    <img src={DEFAULT_UPLOAD_IMAGE} alt="" />
                                                                    <input
                                                                        type="file"
                                                                        onChange={e =>
                                                                            this.uploadImageHandler(e, {
                                                                                spuIndex: i,
                                                                                productIndex: j,
                                                                                skuIndex,
                                                                            })
                                                                        }
                                                                    />
                                                                    <label className="selected-icon"></label>
                                                                </div>
                                                                <i>{val.label}</i>
                                                            </>
                                                        )}
                                                    </label>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                            {j == 0 && !!sizeImgUrl && (
                                <div className="rows-params">
                                    <img src={sizeImgUrl} alt="" style={{ width: '100%' }} />
                                </div>
                            )}
                        </div>
                    </li>,
                );
            }
        }
        return items;
    };

    initSelectedSpuArr = (selectedPackage: any) => {
        if (!selectedPackage?.name) {
            return;
        }
        selectedPackage = JSON.parse(JSON.stringify(selectedPackage));
        const selectedSpuArr: any[] = [];
        let expands: any[] = [];
        selectedPackage?.spuArr?.forEach((spuItem: any, spuIndex: number) => {
            const product: any = {
                spuName: spuItem.name,
                skuArr: [],
            };
            let { spuArr } = this.props.commodity;
            spuArr = spuArr.filter((spu: any) => {
                return spu.name === spuItem.name;
            });
            spuArr = spuArr[0];
            for (let i = 0; i < spuItem.amount; i++) {
                expands[spuIndex + i] = true;
                const attrs: any[] = [];
                spuArr?.attributeArr?.forEach((attr: any) => {
                    attrs.push({
                        attributeName: attr.name,
                        attributeValueLabel: attr.type === 'IMAGE' || attr.type === 'TEXT' ? null : attr.valueArr[0].label,
                    });
                });
                product.skuArr.push({
                    attributeValueArr: attrs,
                    qty: 1,
                });
            }
            selectedSpuArr.push(product);
        });
        this.setState({ expands, selectedSpuArr });
        this.props?.onChangeSku(selectedSpuArr);
    };

    componentWillReceiveProps(nextProps: Prop) {
        if (nextProps.selectedPackage?.name !== this.props.selectedPackage.name) {
            this.initSelectedSpuArr(nextProps.selectedPackage);
        }
    }
    componentDidMount() {
        this.initSelectedSpuArr(this.props.selectedPackage);
    }

    render() {
        return (
            <div>
                <ul className="con_ul">{this.renderItems()}</ul>
            </div>
        );
    }
}
