/**
 * 获取url地址中的查询参数
 * @param url url地址
 */
export function getQueryParams(url: string, key?: string) {
    if (url.indexOf('?') === -1) {
        return {};
    }
    url = url.substring(url.indexOf('?'));
    if (url.indexOf('#') != -1) {
        url = url.substring(0, url.indexOf('#'));
    }
    const queryParams: { [key: string]: string } = {};
    const params = new URLSearchParams(url);
    params.forEach((value: string, key: string) => {
        queryParams[key] = value;
    });
    if (key) {
        return queryParams?.[key];
    }
    return queryParams;
}

/**
 * 动态拼接URL参数
 * @param url 原始URL地睛
 * @param params 要拼接在url后面的参数
 */
export function setQueryParams(url: string, params: { [key: string]: string }) {
    if (!params || typeof params !== 'object') {
        return url;
    }
    const haveQuery = url.includes('?');
    if (!haveQuery) {
        url += '?';
    }
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const value = params[key];
            url = url + `&${key}=${value}`;
        }
    }
    return url.replace('?&', '?');
}

/**
 * 获取文件扩展名
 * @param file 文件
 */
export function getFileExtension(file: string) {
    const fileStrArray = file && file.indexOf('.') ? file.split('.') : [];
    const fileExtension = fileStrArray.length > 0 ? fileStrArray[fileStrArray.length - 1] : '';
    return fileExtension;
}
/**
 * 创建一个script标签
 * @param code JS代码
 */
export function loadScriptString(code: string) {
    var script = document.createElement('script'); //创建一个script标签
    script.type = 'text/javascript';
    try {
        //IE浏览器认为script是特殊元素,不能再访问子节点;报错;
        script.appendChild(document.createTextNode(code));
    } catch (ex) {
        script.text = code;
    }
    document.getElementsByTagName('head')[0].appendChild(script);
}

/**
 * 把字符串的html,转换成DOM节点
 * @param html html字符串
 */
export function parseDom(html: string) {
    var objE = document.createElement('div');
    objE.innerHTML = html;
    return objE.childNodes;
}

export function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

/**
 * 检测浏览器是否支持webp图片,支持返回true,不支持返回false
 */
export async function checkSupportWebp() {
    let support: any = await checkWebpFeature();
    return support;
}

export function checkWebpFeature() {
    var img: any = new Image();
    img.src = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
    return new Promise((resolve, reject) => {
        img.onload = function () {
            resolve(img.width > 0 && img.height > 0);
        };
        img.onerror = function () {
            resolve(false);
        };
    });
}

/**
 * 下划线转换驼峰
 * @param name 需要转换的名称
 */
export function toHump(name: string) {
    return name.replace(/_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
    });
}
/**
 * 驼峰转换下划线
 * @param name 需要转换的名称
 */
export function toLine(name: string) {
    return name.replace(/([A-Z])/g, '_$1').toLowerCase();
}
