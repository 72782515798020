import axios from 'axios';
import Toast from 'src/components/toast';
import { checkSupportWebp, guid } from 'src/utils';

let supportWebp: any = null;
let t: number = -1;
const l: string = window.navigator.language;

const config = JSON.parse(sessionStorage.getItem('config') as string);
// const baseURL = (window as any).businessServer || config.apiBindDomain;

//访问设备
const isPc = () => {
    let device = 0; //0 未知  1 pc  2 移动
    if (/Win32|Win64|Macintosh/i.test(navigator.userAgent)) {
        device = 1;
    }

    if (/Win|Mac|Linux x86_64/i.test(navigator.platform)) {
        device = 1;
    } else if (/iPhone|iPod|iPad|Android|iOS|Linux armv8l|Linux armv7l|Linux aarch64/i.test(navigator.platform)) {
        device = 2;
    }

    return device;
};

const service = axios.create({
    baseURL: process?.env?.REACT_APP_BUSINESS_SERVER,
    timeout: 50000,
    withCredentials: false, // send cookies when cross-domain requests
});

const token = guid();
if (token) {
    sessionStorage.setItem('token', token);
}
// Request interceptors
service.interceptors.request.use(
    async requestConfig => {
        // Add token header to every request, you can add other custom headers here
        if (token) {
            requestConfig.headers['token'] = token;
        }

        if (config?.templatePath) {
            requestConfig.headers['template-path'] = config.templatePath;
        }
        if (supportWebp == null) {
            supportWebp = await checkSupportWebp();
        }
        if (t == -1) {
            t = isPc();
        }
        requestConfig.headers['t'] = t;
        requestConfig.headers['l'] = l;
        requestConfig.headers['webp'] = supportWebp;
        requestConfig.params = requestConfig.params ?? {};
        requestConfig.params._t = Date.now();
        return requestConfig;
    },
    error => {
        Promise.reject(error);
    },
);

// Response interceptors
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.state !== 'SUCCESS') {
            res.message && Toast.error(res.message);
            return Promise.reject(response.data);
        } else {
            return response.data;
        }
    },
    error => {
        if (error?.response?.data?.message) {
            Toast.error(error?.response?.data?.message);
            return Promise.reject(error);
        } else {
            Toast.error('網絡故障，請檢測網絡是否可用');
            return Promise.reject(error);
        }
    },
);

export default service;
