/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';

import './index.scss';

interface Prop {
    onBack: () => void;
    onSearch: (key: string) => void;
}
interface State {
    keyword: string;
}

@injectIntl()
export default class Search extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            keyword: '',
        };
    }
    keywordChangeHandler = (e: any) => {
        this.setState({ keyword: e.target.value });
    };

    searchHandler = () => {
        this.props?.onSearch(this.state.keyword);
    };
    backHandler = () => {
        this.props.onBack();
    };
    componentDidMount() {}

    render() {
        const { intl } = this.props;
        return (
            <div className="alizi-order-wrap clearfix">
                <div className="title alizi-border ellipsis">
                    <a className="title-back" href="javascript:void(0);" onClick={this.backHandler}></a>
                    <FormattedMessage id="query-order" />
                </div>
                <div className="alizi-content">
                    <div className="alizi-rows clearfix rows-id-extends">
                        <div className="rows-params">
                            <input
                                type="tel"
                                name="kw"
                                className="alizi-input-text"
                                style={{ width: '77%' }}
                                placeholder={intl?.formatMessage({ id: 'input-phone-or-order-number' })}
                                onChange={this.keywordChangeHandler}
                            />
                            <input
                                type="button"
                                className="search-btn"
                                value={intl?.formatMessage({ id: 'query.button' })}
                                onClick={this.searchHandler}
                            />
                        </div>
                    </div>
                    <div id="alizi-query-result" className="query_result"></div>
                </div>

                {/* <div className="RecommendGoods">
                    <div className="titleName">
                        <FormattedMessage id="index.order.query.like" />
                    </div>
                    <ul></ul>
                </div> */}
                <div style={{ color: 'red', textAlign: 'center', width: '100%', padding: '10px 0px' }}>
                    <FormattedMessage id="security.info" />
                </div>
                <div className="warn-box">
                    <span>
                        <FormattedMessage id="customer.tip" />
                        {/* <a href="mailto:xzsxzsxiezhen16@yahoo.com" target="_blank">
                            xzsxzsxiezhen16@yahoo.com
                        </a> */}
                    </span>
                </div>

                <div className="tcenter">
                    <a className="backbtn" href="javascript:void(0);" onClick={this.backHandler}>
                        <FormattedMessage id="go-back" />
                    </a>
                </div>
            </div>
        );
    }
}
