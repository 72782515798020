import React from 'react';

import Base from 'src/core/base';

interface Prop {
    commodity: any;
    onChangePackage?: (com: any) => void;
}
interface State {
    selectedPackage: any;
}

export default class Package extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedPackage: {},
        };
    }

    changePackageHandler = (com: any) => {
        this.setState({ selectedPackage: com });
        this.props?.onChangePackage?.(com);
    };

    componentDidMount() {
        const { commodity } = this.props;
        if (commodity?.comboArr?.length) {
            this.changePackageHandler(commodity.comboArr[0]);
        }
    }

    componentWillReceiveProps(nextProps: Prop) {
        if (nextProps.commodity !== this.props.commodity) {
            const { commodity } = nextProps;
            if (commodity?.comboArr?.length) {
                this.changePackageHandler(commodity.comboArr[0]);
            }
        }
    }

    render() {
        const { commodity } = this.props;
        return (
            <div className="alizi-rows clearfix rows-id-params rows-id-params-select">
                <div className="rows-params" style={{ marginLeft: '0rem' }}>
                    {commodity?.comboArr?.map((com: any) => {
                        return (
                            <label
                                key={com.id}
                                alizi-value="1150"
                                alizi-target="#item_price"
                                alizi-fx="alizi.quantity"
                                alizi-fx-params="0"
                                className={`alizi-params ${this.state.selectedPackage.id === com.id ? 'active' : ''}`}
                                title={com.name}
                                style={{ textAlign: 'left' }}
                                onClick={this.changePackageHandler.bind(this, com)}
                            >
                                <input className="alizi-params" type="radio" name="item_params" value={com.name} />
                                {com.name}
                            </label>
                        );
                    })}
                </div>
            </div>
        );
    }
}
