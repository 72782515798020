import React from 'react';
import { FormattedMessage } from 'react-intl';

import Base from 'src/core/base';

interface Prop {
    money: number;
    onSubmit: () => void;
}
interface State {
    commodity: any;
}

export default class FooterBar extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = { commodity: {} };
    }
    /**
     * 提交订单
     */
    submitOrderHandler = async () => {
        setTimeout(() => {
            this.props?.onSubmit();
        }, 0);
    };
    componentDidMount() {
        const commodity = JSON.parse(sessionStorage.getItem('commodity') as string);
        this.setState({
            commodity,
        });
    }

    render() {
        const { intl } = this.props;
        const { commodity } = this.state;
        return (
            <>
                <div className="confirm">
                    <div className="prices">
                        <span className="crname">
                            <FormattedMessage id="combo.price" />:
                        </span>
                        {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' && commodity.currencyDisplay}
                        <span className="alizi-total-price">{this.props.money}</span>
                        {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' && commodity.currencyDisplay}
                        <span className="yuan"></span>
                    </div>
                    <span id="submit_2" className="submit" onClick={this.submitOrderHandler}>
                        <FormattedMessage id="ship-info.submit-order" />
                    </span>
                </div>
                <div className="alizi-rows alizi-id-btn clearfix">
                    <input
                        style={{ display: 'none' }}
                        type="submit"
                        id="alizi-sumit"
                        className="alizi-btn alizi-submit"
                        value={intl?.formatMessage({ id: 'ship-info.submit-order' })}
                        onClick={this.submitOrderHandler}
                    />
                </div>
            </>
        );
    }
}
